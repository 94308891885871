import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Contact } from './contact';
import { Column, Grid } from '../components';

// Static
import topRight from "../svgs/top-right-wave.svg";
import { About } from './about';

export const LandingPage = () => {
	// Get section id from Navlink state & smooth scroll to section
	const { state } = useLocation();
	useEffect(() => {
		const element = document.getElementById(state);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	}, [state]);

	return <>
		<div id="landing" className="relative max-w-screen min-h-screen">
			<div className="container mx-auto px-8 md:px-0">
				<Grid columns={16} className="flex content-center min-h-screen">
					<Column className="flex justify-center col-span-16 lg:col-span-4">
						<div className="md:h-80 md:w-80 w-40 h-40 rounded-full border-[#9ba2ff] bg-[#9ba2ff]"></div>
						<div className="h-80 w-80 rounded-full border-[#9ba2ff] bg-[#9ba2ff] absolute blur-[5rem] top-full right-full"></div>
						<div className="h-[33rem] w-[33rem] rounded-full border-[#9ba2ff] bg-[#ff535b] absolute blur-[30rem] bottom-1/2 right-full"></div>
					</Column>
					<Column className="flex justify-start flex-col col-span-full md:col-span-12">
						<h4 className='font-plex-light text-xl md:text-4xl py-3'>Hello,</h4>
						<h2 className='font-plex-semi-bold text-3xl md:text-7xl py-3'>I'm <span className='text-[#FF5E5B]'>Akshat</span>, welcome to my site!</h2>
						<h4 className='font-plex-light text-xl md:text-4xl py-3'>Here, I share my portfolio and blog new skills and experiences I've gained as a Software Developer.</h4>
					</Column>
				</Grid>
			</div>
			<img src={topRight} alt="top-right-wave" className='absolute top-0 right-0 -z-10' />
		</div>
		<About />
		<Contact />
	</>;
}
