import React from 'react';
import { useNavigate } from 'react-router-dom';

// Static
import bottomLeft from "../svgs/bottom-left-wave.svg";
import topRight from "../svgs/top-right-wave.svg";


export const NotFound = () => {
	const navigate = useNavigate();
	const handleOnClick = () => {
		navigate('/');
	};

	return <div className='relative min-h-screen overflow-hidden'>
		<img src={bottomLeft} alt="bottom-left-wave" className='absolute bottom-0 left-0 -z-10' />
		<img src={topRight} alt="top-right-wave" className='absolute top-0 right-0 -z-10' />
		<div className="h-screen flex justify-center items-center flex-col">
			<h1 className='font-plex-light text-9xl'>404</h1>
			<h3 className='font-plex-light text-6xl mt-1'>Page not found</h3>
			<button onClick={handleOnClick} className="mt-10 transition-all duration-700 ease-in-out hover:bg-right align-center uppercase bg-[200%_auto] font-plex rounded-md p-3 bg-gradient-to-r from-[#ff535b] to-[#9ba2ff] text-[#fff]">Take me home</button>
		</div>
	</div>;
}
