import React from 'react';

interface Props {
	heading?: React.ReactNode | string;
	subheading?: React.ReactNode | string;
	timeRange?: string;
	children?: React.ReactNode;
	icon?: React.ReactNode;
}

export const TimelineItem: React.FC<Props> = ({
	heading,
	subheading,
	timeRange,
	children,
	icon
}: Props) => {

	// We want to display the icon to the left on the border
	// Only if user has passed in an icon
	const span = <span className="timeline-item-icon">
		{icon}
	</span>;

	return <li className='timeline-item'>
		{/* We want to display the icon to the left on the border */}
		{icon && span}
		<div className='pl-9 sm:pl-12'>
			<div className='flex flex-col md:flex-row'>
				<h3 className='timeline-item-heading'>{heading}</h3>
				<h3 className='timeline-item-heading justify-start md:justify-end flex flex-1'>{timeRange}</h3>
			</div>
			{subheading && <h5 className='timeline-item-subheading'>{subheading}</h5>}
			<div className='timeline-item-content'>
				{children}
			</div>
		</div>
	</li>;
}
