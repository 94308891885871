import React from 'react';
import cx from "classnames";

interface Props {
	columns?: Number;
	gap?: Number;
	rows?: Number;
	className?: string;
	children?: React.ReactNode;
	as?: React.ElementType;
}

const Grid: React.FC<Props> = ({
	columns = 16,
	gap = 3,
	rows,
	className,
	children,
	as: BaseComponent = "div"
}: any) => {

	const classNames = cx(
		className,
		"grid",
		{
			[`grid-rows-${rows}`]: !!rows,
			[`grid-cols-${columns}`]: !!columns,
			[`gap-${gap}`]: !!gap,
		}
	);

	return (
		<BaseComponent className={classNames}>
			{children}
		</BaseComponent>
	);
}

export default Grid;
