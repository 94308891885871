import React from 'react';

interface Props {
	children?: React.ReactNode;
}

export const Timeline: React.FC<Props> = ({
	children
}: Props) => {
	return <ul className='relative border-l border-gray-800 timeline'>
		{children}
	</ul>;
};
