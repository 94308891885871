import React from 'react';
import cx from "classnames";

interface Props {
	label: string | React.ReactNode;
	id: string;
	className?: string;
	// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values
	autocomplete?: string;
	Icon?: JSX.Element;
	invalid?: boolean;
	disabled?: boolean;
	[key: string]: any;
	type: "text" | "email";
}
/**
 * ForwardRef here allows us to access the input property from the parent component.
 * If we were to assign a ref property to `<Input />`, it would point to `input` within the template
 */
const Input = React.forwardRef<HTMLInputElement, Props>(({
	label,
	id,
	type = "text",
	Icon = null,
	invalid = false,
	disabled = false,
	...rest
}, ref) => {
	const classNames = cx(
		rest.className ? rest.className : '',
		{
			'rounded-l-none': !!Icon,
			'focus:invalid:ring-pink-500': invalid,
			'focus:invalid:border-pink-500': invalid,
			'invalid:text-pink-700': invalid,
			'invalid:border-pink-700': invalid,
			'disabled:shadow-none': disabled,
			'disabled:border-slate-200': disabled,
			'disabled:text-slate-500': disabled,
			'disabled:bg-slate-50': disabled,
		}
	);

	return <>
		<label htmlFor={id} className="font-plex-semi-bold block">{label}</label>
		<div className='inline-flex items-end w-full'>
			{Icon &&
				<span className='inline-flex items-center rounded-l-md border border-[#9ba2ff] border-r-0 bg-white h-9 px-2'>
					{Icon}
				</span>
			}
			<input
				disabled={disabled}
				ref={ref}
				id={id}
				type={type}
				className={classNames}
				{...rest} />
		</div>
	</>;
});

export default Input;
