import React,
{
	FormEvent,
	useState
} from "react";
import axios from 'axios';
import {
	Button,
	Checkbox,
	Column,
	Grid,
	Input,
	Textarea
} from "../components"

import { ReactComponent as EmailIcon } from "../svgs/icons/email.svg";
import { ReactComponent as CheckmarkIcon } from "../svgs/icons/checkmark--status.svg";

interface FormProps {
	FirstName: string;
	LastName: string;
	Email: string;
	Message: string;
}

export const Contact = () => {
	const [contactForm, setContactForm] = useState<FormProps>({
		FirstName: "",
		LastName: "",
		Email: "",
		Message: ""
	});
	// Toggle notification & hide form once post is complete
	const [postComplete, setPostComplete] = useState<boolean>(false);
	// Toggle send button between disabled and normal state
	const [disabled, setDisabled] = useState<boolean>(false);

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		// Prevent page from reloading (It attempts to POST data via url)
		event.preventDefault();

		// Send data to Cloud function & Reset all values
		if (event.currentTarget.checkValidity()) {
			try {
				await axios
					.post('https://us-east1-akshats-portal.cloudfunctions.net/contact', contactForm);

				setPostComplete(true);
			} catch (error) {
				console.error(error);
			}

			setContactForm({
				FirstName: "",
				LastName: "",
				Email: "",
				Message: ""
			});
		}
	};

	/**
	 * Saving form item values to state
	 */
	const handleInputChange = (event: FormEvent<HTMLInputElement>) => {
		setContactForm({
			...contactForm,
			[event.currentTarget.name]: event.currentTarget.value
		})
	};

	const toggleButton = () => {
		setDisabled(!disabled);
	}

	return <>
		<div id="contact" className="min-h-screen container flex items-center justify-center mx-auto py-40 md:py-0">
			<div className="relative h-fit bg-white px-8 md:px-10 py-10 md:shadow-lg md:ring-1 md:ring-slate-900/10 sm:rounded-3xl lg:w-1/2">
				{
					/**
					 * Display contact form
					 */
					!postComplete &&
					<>
						<div>
							<h3 className="text-5xl font-plex-light">Get in touch</h3>
							<h6 className="text-1xl font-plex-light pt-5">I would love to hear from you. Feel free to provide feedback and suggestions for what you'd like to see on this site!</h6>
						</div>
						<div className="mt-8 pt-8 border-t border-slate-900/10 leading-6 font-plex-light">
							<form onSubmit={handleSubmit}>
								<Grid gap={5} columns={16}>
									<Column col={8}>
										<Input
											required
											minLength={1}
											maxLength={50}
											id="fname"
											name="FirstName"
											value={contactForm.FirstName}
											label="First name*"
											onChange={handleInputChange} />
									</Column>
									<Column col={8}>
										<Input
											required
											minLength={1}
											maxLength={50}
											id="lname"
											name="LastName"
											value={contactForm.LastName}
											label="Last name*"
											onChange={handleInputChange} />
									</Column>
									<Column col={16}>
										<Input
											required
											Icon={<EmailIcon className="icon" />}
											id="email"
											name="Email"
											value={contactForm.Email}
											label="Email*"
											type="email"
											onChange={handleInputChange} />
									</Column>
									<Column col={16}>
										<Textarea
											required
											id="message"
											name="Message"
											value={contactForm.Message}
											minLength={15}
											maxLength={300}
											label="Message*"
											rows={8}
											onChange={handleInputChange} />
									</Column>
									<Column col={16} className="font-plex-semi-bold">
										<Checkbox
											label="I consent to the collection of data submitted through this form."
											id="confirmation"
											name="Confirmation"
											onChange={toggleButton}
											checked={!disabled}
											required />
									</Column>
									<Column className="flex justify-center w-full col-span-16">
										<Button type="submit" disabled={disabled}>Send</Button>
									</Column>
								</Grid>
							</form>
						</div>
					</>
				}
				{
					/**
					 * Display success message that message has been received once post is complete
					 */
					postComplete &&
					<>
						<div>
							<h3 className="text-5xl font-plex-light flex items-center">Received! <span className="pl-3"><CheckmarkIcon className="icon" /></span></h3>
							<h6 className="text-1xl font-plex-light pt-5">Thanks for reaching out!</h6>
						</div>
					</>
				}
			</div>
		</div>
	</>;
}
