import React from "react";
import cx from 'classnames';

interface Props {
	label: string | React.ReactNode;
	id: string;
	name?: string;
	className?: string;
	checked?: boolean;
	value: string;
	invalid?: boolean;
	disabled?: boolean;
	[key: string]: any;
}

const Checkbox = React.forwardRef<HTMLInputElement, Props>(({
	label,
	id,
	invalid = false,
	disabled = false,
	...rest
}, ref) => {

	const classNames = cx(
		rest.className ? rest.className : '',
		{
			'focus:invalid:ring-pink-500': invalid,
			'focus:invalid:border-pink-500': invalid,
			'invalid:text-pink-700': invalid,
			'invalid:border-pink-700': invalid
		},
		{
			'disabled:shadow-none': disabled,
			'disabled:border-slate-200': disabled,
			'disabled:text-slate-500': disabled,
			'disabled:bg-slate-50': disabled,
		}
	);

	return <>
		<div className="flex items-center">
			<input
				className={classNames}
				type="checkbox"
				disabled={disabled}
				id={id}
				ref={ref}
				{...rest} />
			<label htmlFor={id}>{label}</label>
		</div>
	</>
});

export default Checkbox;
