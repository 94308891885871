import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import cx from "classnames";

// Static
import githubLogo from "../../svgs/icons/logo--github.svg";
import linkedinLogo from "../../svgs/icons/logo--linkedin.svg";
import menu from "../../svgs/icons/menu.svg";
import close from "../../svgs/icons/close.svg";


interface Props { };

const Navbar: React.FC<Props> = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenu = (event: any) => {
		setIsMenuOpen(!isMenuOpen);
	}

	useEffect(() => {
		document.addEventListener("keydown", (event: KeyboardEvent) => {
			if (event.key === "Escape") {
				setIsMenuOpen(false);
			}
		}, false);
	});


	const applyClass = ({ isActive }: any) => {
		const classNames = cx(
			"bg-white",
			"py-1",
			{
				'navlink-active': isActive
			}
		);
		return classNames;
	};
	return <>
		<header aria-label="Akshat Patel" className="bg-white sticky top-0 z-50 w-full border-b border-slate-900/10">
			<div className="mx-auto px-10 relative flex items-center h-20">
				<Link to="/">
					<div className="mr-3 flex-none w-[3rem] overflow-hidden md:w-auto">AP</div>
				</Link>
				<div className="relative hidden lg:flex items-center ml-auto">
					<nav className="leading-6">
						<ul className="flex space-x-8 justify-evenly">
							<li>
								<NavLink to="/" state="landing" className={applyClass}>
									Home
								</NavLink>
							</li>
							<li>
								<NavLink to="/" state="about" className={applyClass}>
									About
								</NavLink>
							</li>
							<li>
								<NavLink to="/projects" className={applyClass} end>
									Projects
								</NavLink>
							</li>
							<li>
								<NavLink to="/" state="contact" className={applyClass}>
									Contact
								</NavLink>
							</li>
						</ul>
					</nav>
					<div className="flex border-l space-x-8 border-black ml-8 pl-8">
						<a href="https://github.com/akshat55/">
							<img src={githubLogo} alt="Github logo" className="nav-icon" />
						</a>
						<a href="https://ca.linkedin.com/in/akshat55">
							<img src={linkedinLogo} alt="LinkedIn logo" className="nav-icon" />
						</a>
					</div>
				</div>

				{/* Hamburger icon */}
				<div className="md:hidden w-full inline-flex item-center justify-end">
					<button
						type="button"
						className="rounded-md"
						onClick={toggleMenu}>
						<span className="sr-only">Open menu</span>
						<img className="nav-icon" src={menu} alt="Menu icon" />
					</button>
				</div>

				{/* Mobile menu */}
				{isMenuOpen &&
					<div className="absolute flex w-screen h-screen flex-col left-0 top-0 origin-top-right transform transition md:hidden">
						<div className="rounded-lg w-screen bg-white shadow-lg ring-1 ring-black ring-opacity-5 flex-initial">
							<div className="px-5 pt-5 pb-5">
								<div className="flex items-center justify-end pb-8">
									<button type="button" className="rounded-md" onClick={toggleMenu}>
										<span className="sr-only">Close menu</span>
										<img className="nav-icon" src={close} alt="Close" />
									</button>
								</div>
								<nav>
									<ul className="flex space-y-8 justify-evenly flex-col items-end">
										<li>
											<NavLink to="/" className={applyClass} end>
												Home
											</NavLink>
										</li>
										<li>
											<NavLink to="/projects" className={applyClass} end>
												Projects
											</NavLink>
										</li>
										<li>
											<NavLink to="/about" className={applyClass} end>
												About
											</NavLink>
										</li>
										<li>
											<NavLink to="/contact" className={applyClass} end>
												Contact
											</NavLink>
										</li>
									</ul>
								</nav>
							</div>
						</div>
						{/* Rest of the screen not occupied by the the nav, closes the menu on click */}
						<div className="backdrop-blur flex-auto" onClick={toggleMenu}></div>
					</div>}
			</div>
		</header>
	</>;
}

export default Navbar;
