import React from 'react';
import cx from "classnames";

interface Props {
	colAuto?: boolean;
	col?: Number | "full";
	colStart?: Number | "auto";
	colEnd?: Number | "auto"
	rowAuto?: boolean;
	row?: Number | "full";
	rowStart?: Number | "auto";
	rowEnd?: Number | "auto";
	className?: string;
	children?: React.ReactNode;
	as?: React.ElementType;
}

const Column: React.FC<Props> = ({
	colAuto = false,
	className,
	col,
	colStart,
	colEnd,
	rowAuto = false,
	row,
	rowStart,
	rowEnd,
	children,
	as: BaseComponent = "div"
}: Props) => {

	const classNames = cx(
		className,
		"col",
		{
			'col-auto': colAuto,
			[`col-span-${col}`]: !!col,
			[`col-start-${colStart}`]: !!colStart,
			[`col-end-${colEnd}`]: !!colEnd,
			'row-auto': rowAuto,
			[`row-span-${row}`]: !!row,
			[`row-start-${rowStart}`]: !!rowStart,
			[`row-end-${rowEnd}`]: !!rowEnd
		}
	);

	return (
		<BaseComponent className={classNames}>
			{children}
		</BaseComponent>
	);
}

export default Column;
