import React from 'react';

import {
	Timeline,
	TimelineItem
} from '../components';

import { ReactComponent as BeeIcon } from '../svgs/icons/bee.svg';
import { ReactComponent as CodeIcon } from '../svgs/icons/code.svg';
import { ReactComponent as EducationIcon } from '../svgs/icons/education.svg';

export const About = () => {
	return <div id="about" className="min-h-screen container mx-auto px-8 md:px-0">
		<h3 className='text-4xl py-4 text-[#FF5E5B]'>About me</h3>
		<p>I am a highly driven and passionate software developer with two years of professional experience and a strong foundation in software development principles. I am highly adaptable, having worked across numerous projects ranging from internal applications to external and even open source. I am comfortable working across different stacks and am always looking for new challenges and opportunities to learn and grow as a developer.</p>
		<div className="flex content-center flex-col py-20">
			<h3 className='text-4xl py-4 text-[#FF5E5B]'>Experience</h3>
			<Timeline>
				<TimelineItem
					heading="IBM Canada Ltd."
					timeRange="May 2021 - Present"
					icon={<BeeIcon className='fill-white h-6 w-6' />}>
					<Timeline>
						<TimelineItem heading="Software Developer" icon={<CodeIcon className='fill-[#9ba2ff] h-4 w-4' />}></TimelineItem>
						<TimelineItem
							heading="Full Stack Developer SOC (Internship)"
							timeRange="September 2020 - May 2021"
							icon={<CodeIcon className='fill-[#9ba2ff] h-4 w-4' />}>
							<ul className='list-disc pl-4'>
								<li>Performed code reviews to ensure that the correct work methods and processes were applied.</li>
								<li>Provided constant feedback and support to the new interns throughout the entire integration process through practical activities and time investment.</li>
								<li>Implemented a unified logging mechanism across all applications on the Kubernetes cluster using LogDNA.</li>
							</ul>
						</TimelineItem>
						<TimelineItem
							heading="Full Stack Developer (Internship)"
							timeRange="May 2019 - September 2020"
							icon={<CodeIcon className='fill-[#9ba2ff] h-4 w-4' />}>
							<ul className='list-disc pl-4'>
								<li>Implemented a GDPR compliant web application used by over 900 employees (Sales, Development, Design, Marketing, & Executives) to date, from all around the world.</li>
								<li>Worked across the entire system architecture, from backend & frontend to DevOps and design to implement high quality and scalable applications.</li>
								<li>Set up a Kubernetes cluster and organized multiple access points using ingress to support 24/7 traffic. Implemented various cronjobs that run on various schedule times to ensure data integrity and GDPR compliance.</li>
								<li>Employed IBM's Design Thinking to create products that provide a great user experience along with high performance, security, quality, and stability.</li>
								<li>Mentored new interns to ensure that work was carried out in a structured manner throughout the onboarding process.</li>
							</ul>
						</TimelineItem>
					</Timeline>
				</TimelineItem>
				<TimelineItem
					heading="Seneca College"
					subheading="Full Stack Developer (Research Internship)"
					timeRange="July 2018 - April 2019"
					icon={<EducationIcon className='fill-white h-6 w-6' />}>
					<p>
						Designed and developed a multi-platform web application based on client needs to improve the completion time of numerous business processes by over 50%. Implemented automatic scheduling capable of generating weekly schedules automatically, volunteer management (CRUD), and authentication and authorization features.
						Collaborated with other students in a dynamic team environment using agile and scrum methodologies to formulate a web app design based on design principles to ensure reliability, quality, maintainability, and adaptability.
					</p>
				</TimelineItem>
			</Timeline>
		</div>
	</div>;
}
