import React from 'react';
import cx from 'classnames';

interface Props {
	type?: "submit" | "button" | "reset",
	children?: React.ReactNode;
	className?: string;
	disabled?: boolean;
	[key: string]: any,
}

const Button = ({
	type = "button",
	children,
	disabled = false,
	...rest
}: Props) => {

	const classNames = cx(
		rest.className ? rest.className : '',
		'rounded-md',
		'py-2',
		'px-3',
		'leading-5',
		'text-white',
		'pointer-events-auto',
		'bg-[#9ba2ff]',
		'shadow-sm'
	);

	return (
		<button
			className={classNames}
			type={type}
			disabled={disabled}
			{...rest}>
			{children}
		</button>
	);
}

export default Button;
