import React from 'react';
import cx from "classnames";

interface Props {
	label: string | React.ReactNode;
	id: string;
	name?: string;
	className?: string;
	// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values
	autocomplete?: string;
	invalid?: boolean;
	disabled?: boolean;
	rows?: number;
	cols?: number;
	[key: string]: any;
}
/**
 * ForwardRef here allows us to access the Textarea property from the parent component.
 * If we were to assign a ref property to `<Textarea />`, it would point to `Textarea` within the template
 */
const Textarea = React.forwardRef<HTMLTextAreaElement, Props>(({
	label,
	id,
	invalid,
	disabled,
	...rest
}, ref) => {
	const classNames = cx(
		rest.className ? rest.className : '',
		{
			'focus:invalid:ring-pink-500': invalid,
			'focus:invalid:border-pink-500': invalid,
			'invalid:text-pink-700': invalid,
			'invalid:border-pink-700': invalid,
			'disabled:shadow-none': disabled,
			'disabled:border-slate-200': disabled,
			'disabled:text-slate-500': disabled,
			'disabled:bg-slate-50': disabled,
		}
	);

	return <>
		<label htmlFor={id} className="font-plex-semi-bold block">{label}</label>
		<textarea
			ref={ref}
			id={id}
			className={classNames}
			{...rest} />
	</>;
});

export default Textarea;
