import React from 'react';
import {
	Route,
	Routes,
	BrowserRouter
} from 'react-router-dom';

import { ComingSoon } from './routes/coming-soon';
import { LandingPage } from './routes/landing-page';
import { NotFound } from './routes/not-found';

// Custom components
import { Navbar } from "./components";

function App() {
	return <>
		<BrowserRouter basename='/'>
			<Navbar></Navbar>
			<Routes>
				<Route path='/' element={<LandingPage />} />
				<Route path='/projects' element={<ComingSoon />} />
				<Route path='*' element={<NotFound />} />
			</Routes>
		</BrowserRouter>
	</>;
}

export default App;
